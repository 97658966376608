<!-- 关于我们 -->
<template>
  <div class='about-container'>
    <img src="../../assets/images/banner5.png" alt="" class="banner">
    <div class="container">
      <div class="info-wrap">
        <div class="left">
          <div class="item">幼儿园适应性发展课程</div>
        </div>
        <div class="right">
          <p> 幼儿园适应性发展课程是在新世纪课程改革的背景下发展起来的一种整合性实践课程，课程以多元智能理论、建构主义学习理论和活动课程理论为基础，坚持儿童发展本位。
          </p>
          <p>
            适应性发展课程强调儿童的主动实践，突出课程的适应性和发展性，将幼儿园教育的五大领域有效地整合起来，形成幼儿园整合课程体系；构建了纵向与横向相结合的课程目标，发展了共同性活动和选择性活动相统整的结构体系，重视整体规划、过程管理、集体审议、教师成长在课程实施中的重要作用。
          </p>
          <p>经过近10年的理论探索与改革实践，幼儿园适应性课程逐步走向完善，并对区域性幼儿教育改革起到了积极的推动作用。</p>
          <p>2010年，贵州省教育厅积极贯彻《国家中长期教育改革和发展规划纲要（2010—2020年）》中对学前教育的发展任务，引入幼儿园适应性发展课程，成立贵州省幼儿园课程建设项目组。</p>
          <p>
            由西南大学、贵阳幼儿师范高等专科学校顶层设计，北京师范大学、广西师范大学等高校专家合作，逐渐建构出一套具有清晰的课程理念、课程内容、课程实施和课程评价的整合性课程，并研发出一套集理论、教师用书和幼儿资源于一体的课程资源体系，凸显了课程的地方适宜性、成果前沿性和资源受用性。
          </p>
          <p>
            幼儿园适应性发展课程建设项目组建立了一支“双师型”教研团队，由西南大学副校长，教育学博士陈时见教授、贵阳幼儿师范高等专科学校副校长翟理红教授等各高校专家作为课程前沿引领，各市州一线幼儿园园长组织进行实践探索，结合了一支理论和实践结合的专家支持团队。多年以来，项目组专家支持团队不断辐射课程建设先进理念，扎根指导各地幼儿园课程建设，帮助提升教师教育水平，促进教师专业成长。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {

    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.about-container {
  margin-bottom: 67px;

  .banner {
    width: 100%;
  }

  .info-wrap {
    margin-top: 100px;
    display: flex;

    .left {
      .item {
        width: 202px;
        height: 48px;
        line-height: 48px;
        padding-left: 8px;
        background: #FFA60A;
        font-size: 16px;
        color: #FFFFFF;
      }
    }

    .right {
      padding: 24px 85px 100px 85px;
      border: 1px solid #E4E4E4;

      p {
        font-size: 16px;
        color: #333333;
        line-height: 34px;
        text-indent: 2em;
      }
    }
  }
}
</style>